export default {
   params: {
      transType0: "Mercancía en stock",
      transType1: "Contractual",
      directionType0: "Buy-in",
      directionType1: "Alcance (un precio en una subasta)",
      lockStateType0: "Corriendo",
      lockStateType1: "Cerrado",
      lockStateType2: "Cancelado",
   },
   common: {
      cardMain: "Front Side",
      cardBack: "Back",
      amount: "Amount",
      voucher: "Recharge Voucher",
      withdraw1: "Cuenta de retiro de efectivo",
      withdraw2: "Seleccionar tipo",
      remark: "Nota",
      verify:"Acreditación",
      verifySuccess:"Acreditación superada",
      onlineService: "Servicio al cliente",
      tip1: "Introduzca el importe correcto",
      tip2: "Saldo de cuenta insuficiente",
      confirm: "Defina",
      cancel: "Anulaciones",
      tip: "Llamar la atención sobre algo",
      register: "Inscripción",
      viewMore: "Ver más",
      trade: "Tratos",
      empty: "Ningún contenido en este momento",
      searchPairs: "Buscar pares de divisas",
      and: "Responder cantando",
      next: "El siguiente paso",
      success: "éxitos",
      more: "Más",
      main: "Imagen principal",
      sub: "Foto",
      copySuccess: "Copiar con éxito！",
      copyError: "Fallo de reproducción！",
      view: "Comprobar",
      all: "Completo",
      goSet: "Seguir adelante y establecer",
      available: "Usabilidad",
      confirmCancel: "¿Seguro que quieres cancelarlo?？",
      copy: "Hacer una copia de",
      submit: "Presentar (un informe, etc.)",
      send: "Enviando",
      keep: "Continuar haciendo pedidos",
      revenue: "Ingresos esperados",
   },
   mine: {
      lastTime: "Último acceso",
   },
   head: {
      mineInvite: "Invitar a amigos",
      mineAddress: "Dirección de retirada",
      mine: "Centro de cuentas",
      orderCoin: "Pedido al contado",
      orderContract: "Pedido de contrato",
      orderSecond: "Órdenes de opciones",
      order: "Orden",
      assetsWithdraw: "Retirar dinero",
      assetsRecharge: "Carga de monedas",
      assetsTotal: "Amortización total de activos",
      assets: "Pasivo",
      logout: "Aparecer (en un periódico, etc.)",
      menuTit1: "La situación actual del mercado",
      menuTit2: "Tratos",
      menuTit3: "Gestión financiera",
      menuTit4: "Nuevas monedas en línea",
      menuTit5: "Contest",
      menuTit6: "Event",
      menuTit7: "Blog (préstamo)",
      menuTit8: "Discover",
      menuItem0: "Negociación de opciones",
      menuItem1: "Comercio de monedas",
      menuItem2: "Negociación de contratos",
      menuItem3: "Launchpad",
      menuItem4: "Votar la moneda",
      menuItem5: "Livestream",
      menuItem6: "Offline Event",
      login: "Iniciar sesión",
      register: "Inscripción",
      download: "Escanear código para descargar",
      noPermissions: "Tu dirección IP muestra los países en los que los servicios de CoinTX están restringidos.",
   },
   home: {
      h1: "The First Choice For The Initial Launch",
      p1: "Trade over 50 cryptocurrencies on CoinTX",
      tradeTab1: "Comercio de monedas",
      tradeTab2: "Negociación de contratos",
      tradeTag1: "Subida y bajada de las acciones en un día de negociación",
      tradeTag2: "Monedas calientes",
      tradeTag3: "Prime",
      serviceTit: "Una amplia gama de servicios entre los que elegir",
      serviceTit1: "Operación al contado",
      servicedesc1: "Comprar, vender o negociar criptomonedas al precio vigente en el mercado.",
      serviceTit2: "Contrato perpetuo",
      servicedesc2: "Negociar contratos poniéndose largo o corto",
      serviceTit3: "Gestión financiera",
      servicedesc3: "Compromiso con un clic para aumentar los ingresos diarios",
      serviceTit4: "Prime",
      servicedesc4: "Suscribirse a nuevas fichas de calidad",
      liveTit: "CoinTX en directo",
      whyTit: "CoinTX en directo",
      whyTit1: "Seguridad y confianza",
      whySubtit1: "Compensación interna, auditoría y gestión de riesgos en tiempo real Los monederos fríos están separados de los monederos multifirma para salvaguardar los fondos Auditoría profesional de organizaciones de seguridad de terceros, Auditoría profesional de organizaciones de seguridad de terceros",
      whyLink: "Ver todos los mecanismos de seguridad",
      whyTit2: "Potente motor de agregación de operaciones",
      whySubtit2: "Motor de agregación de latencia ultrabaja, rendimiento masivo, sin fluctuaciones en picos extremos, sin tiempo de inactividad en picos",
      whyTit3: "Plataformas de confianza",
      whySubtit3: "Como una de las 50 plataformas de negociación más importantes del mundo, CoinTX sigue estrictamente las normas del sector y no participa en operaciones de mercado.",
      whyTit4: "Sistema de negociación rápido",
      whySubtit4: "CoinTX le ofrece una interfaz de negociación sencilla y clara y un proceso de funcionamiento cómodo, lo que le permite negociar activos digitales con mayor comodidad.",
      guideTit: "Guía para principiantes",
      guideh1: "Crear una cuenta CoinTX",
      guidep1: "Regístrese ahora y comience su viaje comercial.",
      guideBtn1: "Inscripción",
      guideh2: "Ahorrar dinero en un banco",
      guidep2: "Recarga rápida y abono fácil",
      guideBtn2: "Carga de monedas",
      guideh3: "Inicio de la actividad",
      guidep3: "Empezar a operar ¡Empiece a operar y disfrute de las infinitas posibilidades!",
      guideBtn3: "Comercio de monedas",
      readyTop1: "¿Listo para empezar?？",
      readyMid: "CoinTX cuenta con tecnología avanzada para ofrecerle una experiencia de compra, venta e intercambio rápida. Opere de forma segura con nuestra sencilla plataforma construida solo para usted.",
      readyTit: "Scan to download APP",
      readySubtit: "Android and iOS supported",
      readyDownload: "Download on the",
      readyAppStore: "AppStore",
      readyGetin: "GET IT ON",
      readyGoole: "Google Play",
      faqTit: "FAQ",
      faqTit1: "Experiencia en financiación",
      faqCont1: "Futures Credit es un bono de préstamo de dinero digital para los usuarios de CoinTX para ayudarles a entender el comercio de contratos perpetuos de CoinTX. En comparación con la negociación de futuros tradicional de CoinTX, combinada con la cantidad principal de criptoactivos proporcionada por el usuario, Futures Credit puede utilizarse como un margen de apertura relativamente grande para la negociación de Futures Credit.",
      faqTit2: "¿Qué es CoinBox?",
      faqCont2: "Futures CoinBox es un nuevo producto de CoinTX que te permite enviar una CoinBox de criptomoneda a un amigo.Puedes crear una CoinBox de criptomoneda y compartirla con tus amigos. Cuando tu amigo introduzca su contraseña de CoinBox a través de la aplicación de CoinTX para recibir el regalo, recibirá una cantidad aleatoria de criptomoneda de la CoinBox.",
      faqTit3: "Guía para principiantes para ganar dinero",
      faqCont3: "El último producto de gestión patrimonial de criptomonedas de Futures CoinTX está elaborado por el equipo cuantitativo de CoinTX, formado por gestores de fondos de inversión cuantitativos experimentados, élites financieras del sector de futuros y valores y tecnólogos de MTD. El equipo se estableció en Singapur en 2021 y se centra en la gestión cuantitativa de activos digitales con soluciones profesionales de modelado cuantitativo y capacidades de gestión.Credit se puede utilizar como un margen de apertura relativamente grande en el comercio de Futures Credit.",
      faqTit4: "Únase a nosotros en directo en CoinTX",
      faqCont4: "Futures Live es un producto de retransmisión en directo de los mejores proyectos de blockchain y expertos del sector que ofrecen las charlas y presentaciones más recientes y candentes. Únase a nosotros para descubrir más. El crédito puede utilizarse como un margen de apertura relativamente grande en la negociación de Futures Credit.",
      faqTit5: "Cómo comprar criptomoneda en CoinTX",
      faqCont5: "Futures CoinTX ofrece varios proveedores de servicios de compra de criptodivisas a usuarios de todo el mundo. Consulte nuestro artículo sobre servicios de compra de criptodivisas.Credit se puede utilizar como margen de apertura relativamente grande para las operaciones de Futures Credit.",
      partnerTit: "Socio colaborador",
   },
   assets: {
      tit: "Activos totales",
      recharge: "Carga de monedas",
      withdraw: "Retirar dinero",
      transfer: "Transferencia",
      tab1: "Activos totales",
      tab2: "Cuenta de monedas",
      tab3: "Cuenta del contrato",
      tab4: "Cuenta cuantitativa contractual (CQA)",
      tab5: "Cuenta financiera",
      assetsTotal: "Total (general)",
      assetsReal: "Usabilidad",
      assetsFrozen: "Congelación (préstamo, salario, precio, etc.)",
      proportion: "Porcentaje de activos",
      tip1: "Importe mínimo de retirada",
      tip2: "Los reintegros inferiores al importe mínimo no se abonarán y no podrán reembolsarse.",
      tip3: "Importe mínimo de retirada",
      linkType: "Tipo de cadena",
      rechargeAddress: "Dirección de recarga",
      contractAddress: "Dirección del contrato",
      rechargeRecord: "Registro de llenado de monedas",
      tip4: "No ha completado la configuración de la contraseña, no admite la recarga de monedas por el momento",
      withrawAddress: "Dirección de retirada",
      placeWithrawAddress: "Introduzca la dirección de retirada",
      withrawAmount: "Retirada de efectivo",
      placeWithrawAmount: "Introduzca el importe de la retirada",
      availableLimit: "Crédito disponible",
      fee: "Gastos de tramitación",
      expectedReceipt: "Llegada prevista",
      withdrawRecord: "Registros de retirada de monedas",
   },
   login: {
      login: "Iniciar sesión",
      register: "Inscripción",
      email: "Bandeja de entrada",
      phone: "Teléfonos móviles",
      forget: "¿Ha olvidado su contraseña?？",
      noAccount: "¿No tiene cuenta?？",
      haveAccount: "Ya tiene una cuenta？",
      agree1: "Entiendo.",
      agree2: "Acuerdo de usuario",
      agree3: "Declaración de privacidad",
      resetPassword: "Restablecer contraseña",
      securityTip: "Para garantizar la seguridad de sus activos, no podrá retirar monedas 24 horas después del cambio de contraseña.",
   },
   form: {
      send: "CAPTCHA",
      labelCaptcha: "CAPTCHA, un tipo de prueba de desafío-respuesta (informática)",
      errorCaptcha: "Introduzca el código de verificación",
      labelEmail: "Bandeja de entrada",
      errorEmail: "Introduzca la dirección de correo electrónico correcta",
      placeEmail: "Introduzca su dirección de correo electrónico",
      labelPhone: "Teléfonos móviles",
      errorPhone: "Introduzca el número de teléfono móvil correcto",
      placePhone: "Introduzca su número de teléfono móvil",
      labelPassword: "Criptográfico",
      errorPassword: "Introduzca la contraseña correcta",
      placePassword: "Introduzca su contraseña",
      labelInvite: " Código de invitación (opcional)",
      errorInvite: "Introduzca el código de invitación",
      placeInvite: "Introduzca el código de invitación",
      placeSelece: "Seleccione",
      account_number: "Número de cuenta",
      label_type: "Seleccionar tipo",
      place_type: "Seleccione el tipo",
      label_number: "Número de cuenta",
      place_number: "Número de cuenta",
      wallet_address: 'Dirección de la cartera',
      bank_name: 'Nombre del banco',
      bank_address: "Dirección bancaria",
      name: "Nombre y apellidos",
      placeName: "Introduzca un nombre",
      payee_address: 'Dirección del beneficiario',
      swift_code: "Swift/Swift Code/BIC",
      aba: "ABA",
      branch_number: "Nombre de la sucursal",
      branch_code: "Código de línea",
      bank_code: "Código bancario",
      phone: "Número de teléfono móvil",
      iban: "IBAN",
      sort_code: "Sort Code",
      placeInner: "Introduzca el contenido",
      labelCurrency: "Tipo de moneda",
      placeCurrency: "Seleccione la moneda",
      labelNumber: "Cantidades",
      placeNumber: "Introduzca la cantidad",
      messageNumber: "Introduzca la cantidad",
      labelPrice: "Precios",
      placePrice: "Introduzca un precio",
      placeAmount: "Introduzca el importe",
      labelJoinMoney: "Importe de la adhesión",
      labelOldPassword: "Contraseña antigua",
      placeOldPassword: "Por favor, introduzca la contraseña antigua",
      messageOldPassword: "Por favor, introduzca la contraseña antigua",
      psdTip: "La contraseña debe ser una combinación de letras de 6 ~ 16 bits + dígitos",
      labelNewPassword: "Nueva contraseña",
      placeNewPassword: "Introduzca una nueva contraseña",
      messageNewPassword: "Introduzca una nueva contraseña",
      labelCrmPassword: "Confirmar contraseña",
      placeCrmPassword: "Vuelva a introducir su contraseña",
      messageCrmPassword: "Vuelva a introducir su contraseña",
      labelCountry: "País/zona",
      placeCountry: "Seleccione un país",
      messageCountry: "Seleccione un país",
      labelId: "Número de identificación",
      messageId: "Introduzca el número de identificación",
      labelCaptchaEmail: "El código de verificación se enviará a",
      labelTotalAmount: "Total (importe o valor)",
   },
   table: {
      labelName: "Nombre (de una cosa)",
      labelLastPrice: "último precio",
      labelRang: "24H Arriba y Abajo",
      labelVolume: "Facturación en 24 horas",
      labelVolumeNum: "Volumen 24H",
      labelKline: "Gráfico de líneas K",
      labelHeight: "24H Alta",
      labelLow: "24H Bajo",
      labelOperate: "Plataforma",
      labelTime: "Veces",
      labelCurrency: "Tipo de moneda",
      labelAmount: "Suma de dinero",
      labelState: "Situación",
      state0: "Hecho",
      state1: "Fracasar (por ejemplo, experimentos)",
      state2: "Cancelado",
      withdrawState0: "En espera de aprobación",
      withdrawState1: "Hecho",
      withdrawState2: "Rechazado",
      labelDirection: "Orientaciones",
      labelPrice: "Precios",
      labelLever: "Palanca",
      labelHangNum: "Número ordinal",
      gmtCreate: "Tiempo de creación",
      operate: "Plataforma",
      labelPeriod: "Ciclo de liquidación",
      every: "Cada",
      hour: "Horas",
      day: "Día",
      labelFundRate: "Porcentaje de financiación",
      labelDailyRate: "Tasa de financiación en el mismo día",
      labelLevesCurrency: "Moneda de inversión",
      labelReturn: "Tasa de rendimiento anualizada",
      labelLevesTerm: "Periodo de inversión",
      labelStartAmount: "Importe inicial",
      viewDetails: "Ver detalles",
      labelPairs: "Contraparte de la transacción",
      labelType: "Tipología",
      labelEntrustNumber: "Número de comisiones",
      labelTransNumber: "Número de transacciones",
      labelTotalAmount: "Importe total de la transacción",
      labelEntrustTime: "Tiempo de puesta en servicio",
      labelBuyPrice: "Precio de oferta",
      labelBuyNumber: "Cantidad de compra",
      labelBuyTime: "Momento de la compra",
      labelSellPrice: "Tasa de venta",
      labelProfitLoss: "Creciente y menguante",
      labelNumber: "Cantidades",
      labelMinerTitle: "Nombre financiero",
   },
   foot: {
      menuTit1: "Quiénes somos",
      menuTit2: "Contacte con nosotros",
      menuTit3: "Recursos (como mano de obra o turismo)",
      menuTit4: "Mano",
      menuTit5: "Tratos",
      menuItem1: "Equipo CoinTX",
      menuItem2: "Canal de autenticación",
      menuItem3: "Autorización de cobro a empresas",
      menuItem4: "Asociado",
      menuItem5: "Acerca de CoinTX",
      menuItem6: "Acuerdo de usuario",
      menuItem7: "Correo electrónico oficial",
      menuItem8: "Contratos de CoinTX",
      menuItem9: "Solicitud de monedas",
      menuItem10: "La situación actual del mercado",
      menuItem11: "Academia de Acuñación",
      menuItem12: "Tarifas normales",
      menuItem13: "Tasa y límite de retirada de fondos",
      menuItem14: "Descripción de la moneda",
      menuItem15: "Fianza",
      menuItem16: "Estado de cumplimentación y retirada",
      menuItem17: "Sugerencias y comentarios",
      menuItem18: "Centro de ayuda",
      menuItem19: "Presentación de órdenes de trabajo",
      menuItem20: "Documentación API",
   },
   market: {
      spots: {
         filterTab1: "Autoservicio",
         filterTab2: "Cotizaciones de monedas",
         filterTab3: "Mercado de contratos",
         filterTab4: "Launchpad",
         tabAll: "Completo",
         tab1: "área principal",
         tab2: "Abrir nuevos caminos",
         tab3: "Zona abierta",
         tab4: "Prime",
         tab5: "Monedas",
         tab6: "Contractual",
         tab7: "USDT para siempre",
         tab8: "Moneda completa Perpetua",
      }
   },
   trade:{
      chartMsg: "Información sobre divisas",
      marketPrice:"Valor de mercado",
      limitPrice:"Límite de precio",
      marketPriceTip: "Las órdenes de mercado se compran y venden utilizando el mejor precio del mercado en ese momento.",
      lever: "Palanca",
      handNum: "Número ordinal",
      margin: "Margen (en la negociación de derivados)",
      assets:"Pasivo",
      balance:"Saldos",
      book: "Libro de comisiones",
      quantity: "Importe acumulado",
      navState0: "A la espera de abrir una posición",
      navState1: "Posición actual",
      navState2: "Comisiones históricas",
      navState3: "Comisiones actuales",

      fund: {
         tit1: "Mercado de contratos",
         tit2: "Historial de la tasa de financiación",
         p1: "Un fondo de seguro para evitar que las posiciones de los inversores se reduzcan automáticamente. El fondo se utiliza para mejorar el precio de los mandatos cerrados no ejecutados para evitar que sean asumidos por el sistema de reducción automática de posiciones.",
         p2: "El aumento del importe del fondo de seguro procede de fuertes mandatos planos que se negocian en el mercado a un precio mejor que el de quiebra.",
         p3: "Los saldos actuales e históricos de los fondos de seguros pueden consultarse a continuación.",
         tab1: "USDT para siempre",
         tab2: "Moneda completa Perpetua",
         contract: "Contractual",
      },
   },
   earn: {
      tit: "Compromiso en un clic, ingresos crecientes día a día",
      tab1: "financiación a plazo fijo",
      orderTit: "Órdenes financieras",
      labelTotalApr: "Tasa de rendimiento anualizada",
      labelTimeLimit: "Ciclo del proyecto",
      labelMoney: "Importe de la adhesión",
      labelType: "Método de amortización",
      labelType1: "Servicio de la deuda al vencimiento",
      labelNowBalance: "Saldo actual",
   },
   invite: {
      tit: "Invitar a amigos",
      p: "Escanea este código QR para invitar a tus amigos",
      iviteLink: "Enlace de invitación",
      registerCode: "Código de registro",
   },
   noPermissions: {
      tip: "¡Lo sentimos, su área es temporalmente Jiao ley de defensa semana esta estación de la plataforma, la ley negro para proporcionar servicios para mover!",
   },
   order: {
      tit: "Registro de pedidos",
      tab1: "Órdenes de opciones",
      tab2: "Pedidos de monedas",
      tab3: "Pedido de contrato",
      tab4: "Órdenes financieras",
      filterType0: "Todos los tipos",
      filterType1: "Hecho",
      filterType2: "Cancelado",
      filterType3: "Posición actual",
      filterDirection0: "Todas las direcciones",
      filterDirection1: "Buy-in",
      filterDirection2: "Alcance (un precio en una subasta)",
   },
   personal: {
      tit1: "Nivel de seguridad",
      tit2: "Contraseña de acceso",
      tit3: "Contraseña",
      tit4: "Ajustes de seguridad.",
      strong: "Vigoroso",
      setting: "Establecer",
      settings: "Establecer",
      change: "Variación",
      idTip1: "Autentifique",
      idTip2: "Autenticación antes de proceder al resto de la operación",
      withdrawTip1: "Dirección de retirada",
      withdrawTip2: "Por favor, vincule la dirección de retirada",
      loginPsdTit: "Establecer la contraseña de acceso",
      withdrawPsdTit: "Establecer una contraseña para los reintegros",
      idTit: "Autenticación con nombre real",
      phoneTit: "Unión de teléfonos móviles",
      phoneTip: "No hay cancelación hasta 24 horas después del cambio de teléfono",
      walletTit: "Cartera de encuadernación",
      accountNumber: "Número de cuenta",
      addAddress: "Añadir dirección",

   },
   contest: {
      hot: "Eventos candentes",
   },
   city: {
      albania: "Albania",
      algeria: "Argelia",
     argentina: "Argentina",
     armenia:  "Armenia",
     australia: "Australia",
     pakistan: "Pakistán",
      austria:  "Austria",
     bahrain: "Bahrein",
     belgium: "Bélgica",
     bosnia_and_Herzegovina: "Bosnia y Herzegovina",
     brazil: "Brasil",
     brunei: "Brunei",
     bulgaria: "Bulgaria",
     cambodia: "Camboya",
    canada: "Canadá",
    cameroon: "Camerún",
    chile: "Chile",
    colombia: "Colombia",
    costa_Rica: "Costa Rica",
    croatia: "Croacia",
    cyprus: "Chipre",
    czech_Republic: "República Checa",
    denmark: "Dinamarca",
    dominican_Republic: "República Dominicana",
    egypt: "Egipto",
    estonia: "Estonia",
    ethiopia: "Etiopía",
      finland : "Finlandia",
    france: "Francia",
    georgia: "Georgia",
    germany: "Alemania",
    ghana: "Ghana",
    greece: "Grecia",
    guyana: "Guyana",
    honduras: "Honduras",
    hong_Kong_China: "Hong kong, China",
    hungary: "Hungría",
    iceland: "Islandia",
    ireland: "Irlanda",
    italy: "Italia",
    india: "India",
    indonesia: "Indonesia",
    israel: "Israel",
    iran: "Irán",
    iraq: "Irak",
    japan: "Japón",
    kazakstan: "Kazajstán",
    kenya: "Kenia",
    korea: "Corea del Sur",
    kuwait: "Kuwait",
    kyrgyzstan:"Kirguistán",
    laos:"Laos",
    latvia:"Letonia",
    lithuania:"Lituania",
    luxembourg:"Luxemburgo",
    macao_China:"Macao, China",
    macedonia:"Macedonia",
    malaysia:"Malasia",
    malta:"Malta",
    mexico:"México",
    moldova:"Moldavia",
    monaco:"Mónaco",
    mongolia:"Mongolia",
    montenegro:"República de Montenegro",
    morocco:"Marruecos",
    myanmar:"Myanmar",
    netherlands:"Países Bajos",
    new_Zealand:"Nueva Zelanda",
    nepal:"Nepal",
    nigeria:"Nigeria",
    norway:"Noruega",
    oman:"Omán",
    palestine:"Palestina",
    panama:"Panamá",
    paraguay:"Paraguay",
    peru:"Perú",
    philippines:"Filipinas",
    poland:"Polonia",
    portugal:"Portugal",
    puerto_Rico:"Puerto Rico",
    qatar:"Qatar",
    romania:"Rumanía",
    russia:"Rusia",
    republic_of_Trinidad_and_Tobago:"República de Trinidad y Tobago",
    rwanda:"Rwanda",
    saudi_Arabia:"Arabia Saudita",
    serbia:"Serbia",
    singapore:"Singapur",
    slovakia:"Eslovaquia",
    slovenia:"Eslovenia",
    south_Africa:"Sudáfrica",
    spain:"España",
    sri_Lanka:"Sri Lanka",
    sweden:"Suecia",
    switzerland:"Suiza",
    taiwan_China:"Taiwán, China",
    tajikistan:"Tayikistán",
    tanzania:"Tanzania",
    thailand:"Tailandia",
    turkey:"Turquía",
    turkmenistan:"Turkmenistán",
    ukraine:"Ucrania",
    united_Arab_Emirates:"Emiratos Árabes Unidos",
    united_Kingdom:"Reino Unido",
    united_States:"Estados Unidos",
    uzbekistan:"Uzbekistán",
    venezuela:"Venezuela",
    vietnam:"Vietnam",
    afghanistan:"Afganistán",
    angola:"Angola",
    azerbaijan:"Azerbaiyán",
    bangladesh:"Bangladesh",
    belarus:"Bielorrusia",
    belize:"Belice",
    benin:"Benin",
    bhutan:"Bhután",
    bolivia:"Bolivia",
    botswana:"Botswana",
    british_Virgin_Islands:"Islas Vírgenes Británicas",
    burkina_Faso:"Burkina Faso",
    burundi:"Burundi",
    cape_Verde:"Cabo Verde",
    cayman_Islands:"Islas Caimán",
    central_African_Republic:"República Centroafricana",
    chad:"Chad",
    comoros:"Comoras",
    the_Republic_of_the_Congo:"Congo (brazzaville)",
    democratic_Republic_of_the_Congo:"República Democrática del Congo (rdc)",
    djibouti:"Djibouti",
    ecuador:"Ecuador",
    el_Salvador:"El Salvador",
    equatorial_Guinea:"Guinea Ecuatorial",
    eritrea:"Eritrea",
    fiji:"Fiji",
    gabon:"Gabón",
    gambia:"Gambia",
    greenland:"Groenlandia",
    guatemala:"Guatemala",
    guinea:"Guinea",
    haiti:"Haití",
    isle_of_Man:"Isla de Man",
    cote_d_Ivoire:"Costa de Marfil",
    jamaica:"Jamaica",
    jordan:"Jordania",
    lebanon:"Líbano",
    lesotho:"Lesotho",
    liberia:"Liberia",
    libya:"Libia",
    madagascar:"Madagascar",
    malawi:"Malawi",
    maldives:"Maldivas",
    mali:"Malí",
    mauritania:"Mauritania",
    mauritius:"Mauricio",
    mozambique:"Mozambique",
    namibia:"Namibia",
    nicaragua:"Nicaragua",
    republic_of_Niger:"Níger",
    north_Korea:"Corea del Norte",
    reunion:"Reunión",
    san_Marino:"San Marino",
    senegal:"Senegal",
    sierra_Leone:"Sierra Leona",
    somalia:"Somalia",
    sudan:"Sudán",
    suriname:"Surinam",
    eswatini:"Swazilandia",
    syria:"Siria",
    togo:"Togo",
    tonga:"Tonga",
    tunisia:"Túnez",
    united_States_Virgin_Islands:"Islas Vírgenes de los Estados Unidos",
    uganda:"Uganda",
    uruguay:"Uruguay",
    vatican:"Vaticano",
    yemen:"Yemen",
    yugoslavia:"Yugoslavia",
    zambia:"Zambia",
    zimbabwe:"Zimbabwe",
    china:"China",
 }
};






