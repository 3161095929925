export default {
   params: {
      transType0: "상품 재고 보유",
      transType1: "계약",
      directionType0: "바이인",
      directionType1: "도달 범위(경매의 가격)",
      lockStateType0: "실행 중",
      lockStateType1: "Closed",
      lockStateType2: "취소됨",
   },
   common: {
      cardMain: "Front Side",
      cardBack: "Back",
      amount: "금액",
      voucher: "충전 증명서",
      withdraw1: "현금 인출 계좌",
      withdraw2: "유형 선택",
      remark: "설명",
      verify:"인증",
      verifySuccess:"인증 통과",
      onlineService: "클라이언트 서비스",
      tip1: "정확한 금액을 입력하세요.",
      tip2: "계정 잔액 부족",
      confirm: "정의",
      cancel: "취소",
      tip: "STH에 주목하세요.",
      register: "등록",
      viewMore: "자세히 보기",
      trade: "거래",
      empty: "현재 콘텐츠가 없습니다.",
      searchPairs: "통화쌍 검색",
      and: "노래로 응답",
      next: "다음 단계",
      success: "성공 사례",
      more: "더 보기",
      main: "메인 사진",
      sub: "사진",
      copySuccess: "복사 성공！",
      copyError: "복제 실패！",
      view: "체크 아웃",
      all: "전체",
      goSet: "계속해서",
      available: "사용성",
      confirmCancel: "취소하시겠습니까？",
      copy: "의 복사본을 만듭니다.",
      submit: "제출(보고서 등)",
      send: "보내기",
      keep: "주문 계속",
      revenue: "예상 이익",
   },
   mine: {
      lastTime: "마지막 로그인",
   },
   head: {
      mineInvite: "친구 초대",
      mineAddress: "출금 주소",
      mine: "계정 센터",
      orderCoin: "현물 주문",
      orderContract: "계약 주문",
      orderSecond: "옵션 주문",
      order: "(구매) 주문",
      assetsWithdraw: "돈 인출",
      assetsRecharge: "동전 충전",
      assetsTotal: "총 자산 감가상각비",
      assets: "부채",
      logout: "(신문 등에) 등장",
      menuTit1: "현재 시장 상황",
      menuTit2: "거래",
      menuTit3: "재무 관리",
      menuTit4: "새로운 코인 출시",
      menuTit5: "Contest",
      menuTit6: "Event",
      menuTit7: "블로그(외래어)",
      menuTit8: "Discover",
      menuItem0: "옵션 거래",
      menuItem1: "코인 거래",
      menuItem2: "계약 거래",
      menuItem3: "Launchpad",
      menuItem4: "코인 투표",
      menuItem5: "Livestream",
      menuItem6: "Offline Event",
      login: "로그인",
      register: "등록",
      download: "다운로드할 코드 스캔",
      noPermissions: "IP 주소는 코인스토어의 서비스가 제한되는 국가를 보여줍니다.",
   },
   home: {
      h1: "The First Choice For The Initial Launch",
      p1: "Trade over 50 cryptocurrencies on CoinTX",
      tradeTab1: "코인 거래",
      tradeTab2: "계약 거래",
      tradeTag1: "하루 거래에서 주식의 상승과 하락",
      tradeTag2: "핫 코인",
      tradeTag3: "Prime",
      serviceTit: "선택할 수 있는 다양한 서비스",
      serviceTit1: "현물 거래",
      servicedesc1: "암호화폐를 일반적인 시장 가격으로 구매, 판매 또는 거래합니다.",
      serviceTit2: "영구 계약",
      servicedesc2: "롱 또는 숏 포지션으로 계약 거래",
      serviceTit3: "재무 관리",
      servicedesc3: "일일 수입을 늘리기 위한 원클릭 서약",
      serviceTit4: "Prime",
      servicedesc4: "양질의 신규 토큰 구독",
      liveTit: "CoinTX 직접 인터넷 방송",
      whyTit: "CoinTX 직접 인터넷 방송",
      whyTit1: "안전하고 신뢰할 수 있는 서비스",
      whySubtit1: "실시간 내부 청산, 감사 및 위험 관리 콜드월렛은 다중 서명 지갑과 분리되어 자금을 보호합니다. 전문 타사 보안 기관 감사, 전문 타사 보안 기관 감사",
      whyLink: "모든 보안 메커니즘 보기",
      whyTit2: "강력한 거래 집계 엔진",
      whySubtit2: "초저지연 집계 엔진, 대규모 처리량, 극한 피크 시 지터 없음, 피크 시 다운타임 없음",
      whyTit3: "신뢰할 수 있는 플랫폼",
      whySubtit3: "세계 50대 거래 플랫폼 중 하나인 코인스토어는 업계 규칙을 엄격하게 준수하며 시장 운영에 관여하지 않습니다.",
      whyTit4: "빠른 거래 시스템",
      whySubtit4: "코인스토어는 간단하고 명확한 거래 인터페이스와 편리한 운영 프로세스를 제공하여 디지털 자산을 보다 편리하게 거래할 수 있습니다.",
      guideTit: "초보자 가이드",
      guideh1: "코인스토어 계정 만들기",
      guidep1: "지금 가입하고 트레이딩 여정을 시작하세요!",
      guideBtn1: "등록",
      guideh2: "은행에 돈을 저축하세요",
      guidep2: "빠른 충전과 간편한 크레딧!",
      guideBtn2: "동전 충전",
      guideh3: "거래 시작",
      guidep3: "거래 시작 거래를 시작하고 무한한 가능성을 즐기세요!",
      guideBtn3: "코인 거래",
      readyTop1: "시작할 준비가 되셨나요?？",
      readyMid: "코인스토어는 빠른 구매, 판매, 거래 경험을 제공하는 첨단 기술을 보유하고 있습니다. 여러분만을 위해 만들어진 간편한 플랫폼으로 안전하게 거래하세요.",
      readyTit: "Scan to download APP",
      readySubtit: "Android and iOS supported",
      readyDownload: "Download on the",
      readyAppStore: "AppStore",
      readyGetin: "GET IT ON",
      readyGoole: "Google Play",
      faqTit: "FAQ",
      faqTit1: "펀딩 경험",
      faqCont1: "선물 크레딧은 코인스토어 사용자가 코인스토어 무기한 계약 거래를 이해하는 데 도움이 되는 디지털 화폐 대출 보너스입니다. 사용자가 제공한 암호화폐 자산의 원금과 결합된 코인스토어의 기존 선물 거래와 비교하여 선물 크레딧은 선물 크레딧 거래를 위한 상대적으로 큰 개시 증거금으로 사용할 수 있습니다.",
      faqTit2: "코인박스란 무엇인가요?",
      faqCont2: "선물 코인박스는 친구에게 암호화폐 코인박스를 보낼 수 있는 코인스토어의 새로운 상품 기능으로, 암호화폐 코인박스를 생성하여 친구와 공유할 수 있습니다. 친구가 선물을 받기 위해 코인스토어 앱에서 코인박스 비밀번호를 입력하면 코인박스에서 무작위로 암호화폐를 받게 되며, 선물 크레딧은 선물 크레딧 거래에서 포지션을 개설할 때 비교적 큰 마진으로 사용할 수 있습니다.",
      faqTit3: "수익 창출을 위한 초보자 가이드",
      faqCont3: "선물 코인스토어의 최신 암호화폐 자산 관리 상품은 경험이 풍부한 퀀트 투자 펀드 매니저, 선물 및 증권 업계의 금융 엘리트, BAT 기술자로 구성된 코인스토어의 퀀트 팀이 제작했습니다. 이 팀은 2021년 싱가포르에 설립되어 전문적인 퀀트 모델링 솔루션과 관리 능력을 갖춘 디지털 자산의 퀀트 관리에 중점을 두고 있으며, 선물 신용거래에서 신용은 비교적 큰 개시 증거금으로 사용할 수 있습니다.",
      faqTit4: "코인스토어에서 라이브 참여하기",
      faqCont4: "Futures Live는 최고의 메모리 블록 체인 프로젝트와 업계 전문가를 초청하여 최신 및 가장 인기 있는 강의와 강연을 전달하는 생방송 제품입니다.우리와 함께 더 많은 멋진 속성을 탐험하십시오.Credit은 Futures Credit 거래에서 상대적으로 큰 창고 개설 보증금으로 사용할 수 있습니다.",
      faqTit5: "CoinTX에서 암호화폐를 구매하는 방법",
      faqCont5: "Futures CoinTX는 전 세계 사용자에게 여러 타사 암호화폐 구매 서비스 제공업체를 제공합니다.암호화폐 구매 서비스에 관한 기사를 참조하십시오.Credit은 Futures Credit 거래에서 상대적으로 큰 창고 개설 보증금으로 사용할 수 있습니다.",
      partnerTit: "파트너",
   },
   assets: {
      tit: "총자산",
      recharge: "충전",
      withdraw: "동전 인출",
      transfer: "회전",
      tab1: "자산 총액",
      tab2: "코인 계좌",
      tab3: "계약 계정",
      tab4: "계약 정량화 계정",
      tab5: "재테크 계좌",
      assetsTotal: "합계",
      assetsReal: "사용 가능",
      assetsFrozen: "동결",
      proportion: "자산 비율",
      tip1: "최소 현금 인출 금액",
      tip2: "최소 금액 이하의 현금 인출은 입금되지 않으며 환불할 수 없습니다.",
      tip3: "최소 현금 인출 금액",
      linkType: "체인 유형",
      rechargeAddress: "충전 주소",
      contractAddress: "계약 주소",
      rechargeRecord: "충전 기록",
      tip4: "암호 설정이 완료되지 않았습니다. 충전이 지원되지 않습니다.",
      withrawAddress: "현금 인출 주소",
      placeWithrawAddress: "현금 인출 주소를 입력하십시오.",
      withrawAmount: "현금 인출 금액",
      placeWithrawAmount: "현금 인출 금액을 입력하십시오.",
      availableLimit: "가용 한도",
      fee: "수수료",
      expectedReceipt: "예상 입금",
      withdrawRecord: "동전 인출 기록",
   },
   login: {
      login: "로그인",
      register: "등록",
      email: "메일박스",
      phone: "핸드폰",
      forget: "암호를 잊었습니다.？",
      noAccount: "계정 없음？",
      haveAccount: "계정 있음？",
      agree1: "이해해요",
      agree2: "사용자 프로토콜",
      agree3: "개인 정보 보호 프로토콜",
      resetPassword: "비밀번호 재설정",
      securityTip: "자산 보안을 위해 비밀번호를 수정한 후 24시간 동안 코인을 인출할 수 없습니다.",
   },
   form: {
      send: "인증 코드 보내기",
      labelCaptcha: "인증 코드",
      errorCaptcha: "인증 코드를 입력하십시오.",
      labelEmail: "메일박스",
      errorEmail: "올바른 이메일 주소를 입력하십시오.",
      placeEmail: "메일박스 입력",
      labelPhone: "핸드폰",
      errorPhone: "정확한 핸드폰 번호를 입력하세요.",
      placePhone: "핸드폰 번호를 입력하세요.",
      labelPassword: "암호",
      errorPassword: "올바른 암호를 입력하십시오.",
      placePassword: "암호를 입력하십시오.",
      labelInvite: " 초대 코드 (선택 사항)",
      errorInvite: "초대 코드를 입력하십시오.",
      placeInvite: "초대 코드를 입력하십시오.",
      placeSelece: "선택하십시오.",
      account_number: "계정 번호",
      label_type: "유형 선택",
      place_type: "유형 선택",
      label_number: "계정 번호",
      place_number: "계정 번호",
      wallet_address: '지갑 주소',
      bank_name: '은행 이름',
      bank_address: "은행 주소",
      name: "이름",
      placeName: "이름을 입력하십시오.",
      payee_address: '수취인 주소',
      swift_code: "Swift/Swift Code/BIC",
      aba: "ABA",
      branch_number: "지점 이름",
      branch_code: "지점 코드",
      bank_code: "은행 코드",
      phone: "핸드폰 번호",
      iban: "IBAN",
      sort_code: "Sort Code",
      placeInner: "등록 정보를 입력하십시오.",
      labelCurrency: "화폐 종류",
      placeCurrency: "코인을 선택하세요",
      labelNumber: "수량",
      placeNumber: "수량을 입력하십시오.",
      messageNumber: "수량을 입력하십시오.",
      labelPrice: "가격",
      placePrice: "가격을 입력하십시오.",
      placeAmount: "금액을 입력하세요",
      labelJoinMoney: "가입금액",
      labelOldPassword: "이전 암호",
      placeOldPassword: "이전 암호를 입력하십시오.",
      messageOldPassword: "이전 암호를 입력하십시오.",
      psdTip: "암호는 6 ~ 16비트 문자 + 숫자의 조합이어야 합니다.",
      labelNewPassword: "새 암호",
      placeNewPassword: "새 암호를 입력하십시오.",
      messageNewPassword: "새 암호를 입력하십시오.",
      labelCrmPassword: "암호 확인",
      placeCrmPassword: "암호를 다시 입력하십시오.",
      messageCrmPassword: "암호를 다시 입력하십시오.",
      labelCountry: "국가 / 지역",
      placeCountry: "국가를 선택하세요",
      messageCountry: "국가를 선택하세요",
      labelId: "id 번호",
      messageId: "id 번호를 입력하십시오.",
      labelCaptchaEmail: "인증 코드가 다음으로 전송됩니다.",
      labelTotalAmount: "총액",
   },
   table: {
      labelName: "이름",
      labelLastPrice: "최신 가격",
      labelRang: "24H 등락폭",
      labelVolume: "24H 거래액",
      labelVolumeNum: "24H 거래량",
      labelKline: "K선도",
      labelHeight: "24H 높음",
      labelLow: "24H 낮음",
      labelOperate: "작업",
      labelTime: "시간",
      labelCurrency: "화폐 종류",
      labelAmount: "금액",
      labelState: "상태",
      state0: "완료됨",
      state1: "실패",
      state2: "취소됨",
      withdrawState0: "보류 중",
      withdrawState1: "완료됨",
      withdrawState2: "거부됨",
      labelDirection: "방향",
      labelPrice: "가격",
      labelLever: "레버",
      labelHangNum: "손수",
      gmtCreate: "생성 시간",
      operate: "작업",
      labelPeriod: "결제 주기",
      every: "매번",
      hour: "시간",
      day: "일",
      labelFundRate: "자금 비율",
      labelDailyRate: "당일 자금비율",
      labelLevesCurrency: "투자 화폐",
      labelReturn: "연간 수익률",
      labelLevesTerm: "투자 기간",
      labelStartAmount: "시작 금액",
      viewDetails: "자세히 보기",
      labelPairs: "거래 쌍",
      labelType: "유형",
      labelEntrustNumber: "위탁 수량",
      labelTransNumber: "거래 수량",
      labelTotalAmount: "거래 총액",
      labelEntrustTime: "위탁 시간",
      labelBuyPrice: "매입가",
      labelBuyNumber: "매입 수량",
      labelBuyTime: "구매 기간",
      labelSellPrice: "판매 가격",
      labelProfitLoss: "손익",
      labelNumber: "수량",
      labelMinerTitle: "재테크 명칭",
   },
   foot: {
      menuTit1: "우리에 대해",
      menuTit2: "연락처",
      menuTit3: "리소스",
      menuTit4: "도움말",
      menuTit5: "거래",
      menuItem1: "CoinTX 팀",
      menuItem2: "공식 인증 채널",
      menuItem3: "비즈니스 수금 권한 부여",
      menuItem4: "동업자",
      menuItem5: "코인스토어로 들어가기",
      menuItem6: "사용자 프로토콜",
      menuItem7: "공식 메일박스",
      menuItem8: "CoinTX 계약",
      menuItem9: "코인 신청",
      menuItem10: "시세",
      menuItem11: "코인스토어 아카데미",
      menuItem12: "요율 기준",
      menuItem13: "동전 인출 수수료 & 동전 인출 한도액",
      menuItem14: "화폐 종류 소개",
      menuItem15: "보안",
      menuItem16: "충전 & 코인 인출 상태",
      menuItem17: "권장사항 및 피드백",
      menuItem18: "도움말 센터",
      menuItem19: "작업 명세서 제출",
      menuItem20: "API 문서",
   },
   market: {
      spots: {
         filterTab1: "자유 선택",
         filterTab2: "코인 시세",
         filterTab3: "계약 시세",
         filterTab4: "Launchpad",
         tabAll: "모두",
         tab1: "주 영역",
         tab2: "혁신",
         tab3: "개방 구역",
         tab4: "Prime",
         tab5: "코인",
         tab6: "계약",
         tab7: "USDT영속",
         tab8: "전체 화폐 종류가 영구하다.",
      }
   },
   trade:{
      chartMsg: "통화 정보",
      marketPrice:"시가",
      limitPrice:"제한 가격",
      marketPriceTip: "시가 위탁은 현재 시장에서 가장 좋은 가격으로 매매한다.",
      lever: "레버",
      handNum: "손수",
      margin: "보증금",
      assets:"자산",
      balance:"잔액",
      book: "위탁부",
      quantity: "누적량",
      navState0: "창고 개설을 기다리다",
      navState1: "현재 보유",
      navState2: "역사적 의뢰",
      navState3: "현재 위임",

      fund: {
         tit1: "계약 시장",
         tit2: "자금 비율 기록",
         p1: "보험 기금은 투자자의 창고가 자동으로 창고를 줄이는 것을 피한다.이 기금은 자동 창고 감축 시스템에 의해 인수되지 않도록 집행되지 않은 강평 위탁의 가격을 개선하는 데 쓰인다.",
         p2: "보험기금액의 증가는 강평위탁이 시장에서 파산가격보다 우월한 가격거래에서 온것이다.",
         p3: "아래 보험 기금의 현재 및 과거 잔액을 볼 수 있다。",
         tab1: "USDT영속",
         tab2: "전체 화폐 종류가 영구하다.",
         contract: "계약",
      },
   },
   earn: {
      tit: "원터치 질권, 매일 증수",
      tab1: "정기 재테크",
      orderTit: "재테크 주문",
      labelTotalApr: "연간 수익률",
      labelTimeLimit: "프로젝트 주기",
      labelMoney: "가입금액",
      labelType: "회수 방식",
      labelType1: "만기가 되어 원리금을 상환하다.",
      labelNowBalance: "현재 잔액",
   },
   invite: {
      tit: "친구 초대",
      p: "이 QR코드를 스캔하여 친구 초대",
      iviteLink: "초대 링크",
      registerCode: "등록 코드",
   },
   noPermissions: {
      tip: "미안합니다만, 당신이 있는 지역은 잠시 주법 방주 이 강역에 초점을 맞추고, 흑법은 서버 이동을 제공합니다",
   },
   order: {
      tit: "주문 기록",
      tab1: "옵션 주문",
      tab2: "코인 주문",
      tab3: "계약 주문",
      tab4: "재테크 주문",
      filterType0: "모든 유형",
      filterType1: "완료됨",
      filterType2: "취소됨",
      filterType3: "현재 보유",
      filterDirection0: "모든 방향",
      filterDirection1: "매입",
      filterDirection2: "매도",
   },
   personal: {
      tit1: "보안 수준",
      tit2: "로그인 암호",
      tit3: "거래 암호",
      tit4: "보안 설정",
      strong: "강하다",
      setting: "설치",
      settings: "설치",
      change: "변경",
      idTip1: "인증",
      idTip2: "나머지 작업은 인증을 받아야 합니다.",
      withdrawTip1: "인출 주소",
      withdrawTip2: "바인딩 인출 주소를 진행하세요",
      loginPsdTit: "로그인 암호 설정",
      withdrawPsdTit: "현금 인출 암호 설정",
      idTit: "실명인증",
      phoneTit: "휴대폰 바인딩",
      phoneTip: "휴대폰 교체 후 24시간 취소 금지",
      walletTit: "지갑 바인딩",
      accountNumber: "계정 번호",
      addAddress: "주소 추가",

   },
   contest: {
      hot: "인기 이벤트",
   },
   city: {
      albania: "알바니아",
      algeria: "알제리",
      argentina: "아르헨티나",
      armenia: "아르메니아",
      australia: "호주",
      pakistan: "파키스탄",
      austria: "오스트리아",
      bahrain: "바레인",
      belgium: "벨기에",
      bosnia_and_Herzegovina: "보스니아 헤르체고비나",
      brazil: "브라질",
      brunei: "브루나이",
      bulgaria: "불가리아",
      cambodia: "캄보디아",
      canada: "캐나다",
      cameroon: "카메룬",
      chile: "칠레",
      colombia: "콜롬비아",
      costa_Rica: "코스타리카",
      croatia: "크로아티아",
      cyprus: "키프로스",
      czech_Republic: "체코",
      denmark: "덴마크",
      dominican_Republic: "도미니카",
      egypt: "이집트",
      estonia: "에스토니아",
      ethiopia: "에티오피아",
      finland: "핀란드",
      france: "프랑스",
      georgia: "그루지야",
      germany: "독일",
      ghana: "가나",
      greece: "그리스",
      guyana: "가이아나",
      honduras: "온두라스",
      hong_Kong_China: "중국 홍콩",
      hungary: "헝가리",
      iceland: "아이슬란드",
      ireland: "아일랜드",
      italy: "이탈리아",
      india: "인도",
      indonesia: "인도네시아",
      israel: "이스라엘",
      iran: "이란",
      iraq: "이라크",
      japan: "일본",
      kazakstan: "카자흐스탄",
      kenya: "케냐",
      korea: "한국",
      kuwait: "쿠웨이트",
      kyrgyzstan: "키르기스스탄",
      laos: "라오스",
      Latvia: "라트비아",
      lithuania: "리투아니아",
      luxembourg: "룩셈부르크",
      macao_China: "중국 마카오",
      macedonia: "마케도니아",
      malaysia: "말레이시아",
      malta: "몰타",
      mexico: "멕시코",
      moldova: "몰도바",
      monaco: "모나코",
      mongolia: "몽골",
      montenegro: "몬테네그로",
      morocco: "모로코",
      myanmar: "미얀마",
      netherlands: "네덜란드",
      new_Zealand: "뉴질랜드",
      nepal: "네팔",
      nigeria: "나이지리아",
      norway: "노르웨이",
      oman: "오만",
      palestine: "팔레스타인",
      panama: "파나마",
      paraguay: "파라과이",
      peru: "페루",
      philippines: "필리핀",
      poland: "폴란드",
      portugal: "포르투갈",
      puerto_Rico: "푸에르토리코",
      qatar: "카타르",
      romania: "루마니아",
      russia: "러시아",
      republic_of_Trinidad_and_Tobago: "트리니다드 토바고",
      rwanda: "르완다",
      saudi_Arabia: "사우디아라비아",
      serbia: "세르비아",
      singapore: "싱가포르",
      slovakia: "슬로바키아",
      slovenia: "슬로베니아",
      south_Africa: "남아프리카",
      spain: "스페인",
      sri_Lanka: "스리랑카",
      sweden: "스웨덴",
      switzerland: "스위스",
      taiwan_China: "대만",
      tajikistan: "타지키스탄",
      tanzania: "탄자니아",
      thailand: "태국",
      turkey: "터키",
      turkmenistan: "투르크메니스탄",
      ukraine: "영국",
      united_Arab_Emirates: "미국",
      united_Kingdom: "우즈베키스탄",
      united_States: "베네수엘라",
      uzbekistan: "베트남",
      venezuela: "아프가니스탄",
      vietnam: "베트남",
      afghanistan: "아프가니스탄",
      angola: "앙골라",
      azerbaijan: "아제르바이잔",
      bangladesh: "방글라데시",
      belarus: "벨라루스",
      belize: "벨리즈",
      benin: "베냉",
      bhutan: "부탄",
      bolivia: "볼리비아",
      botswana: "보츠와나",
      british_Virgin_Islands: "영국령 버진 아일랜드",
      burkina_Faso: "부르키나파소",
      burundi: "부룬디",
      cape_Verde: "카보베르데",
      cayman_Islands: "케이맨 제도",
      central_African_Republic: "중앙아프리카",
      chad: "차드",
      comoros: "코모로",
      the_Republic_of_the_Congo: "콩고 (부)",
      democratic_Republic_of_the_Congo: "콩고 (김)",
      djibouti: "지부티",
      ecuador: "에콰도르",
      el_Salvador: "엘살바도르",
      equatorial_Guinea: "적도 기니",
      eritrea: "에리트레아",
      fiji: "피지",
      gabon: "가봉",
      gambia: "감비아",
      greenland: "그린란드",
      guatemala: "과테말라",
      guinea: "기니",
      haiti: "아이티",
      isle_of_Man: "맨 섬",
      cote_d_Ivoire: "코트디부아르",
      jamaica: "자메이카",
      jordan: "요르단",
      lebanon: "레바논",
      lesotho: "레소토",
      liberia: "라이베리아",
      libya: "리비아",
      madagascar: "마다가스카르",
      malawi: "말라위",
      maldives: "몰디브",
      mali: "말리",
      mauritania: "모리타니",
      mauritius: "모리셔스",
      mozambique: "모잠비크",
      namibia: "나미비아",
      nicaragua: "니카라과",
      republic_of_Niger: "니제르",
      north_Korea: "조선",
      reunion: "레위니옹",
      san_Marino: "산마리노",
      senegal: "세네갈",
      sierra_Leone: "시에라리온",
      somalia: "소말리아",
      sudan: "수단",
      suriname: "수리남",
      eswatini: "스와질란드",
      syria: "시리아",
      togo: "토고",
      tonga: "통가",
      tunisia: "튀니지",
      united_States_Virgin_Islands: "미국령 버진 아일랜드",
      uganda: "우간다",
      uruguay: "우루과이",
      vatican: "바티칸",
      yemen: "예멘",
      yugoslavia: "유고슬라비아",
      zambia: "잠비아",
      zimbabwe: "짐바브웨",
      china: "중국",
   }
};






