export default {
   params: {
      transType0: "Marchandises en stock",
      transType1: "Contractuel",
      directionType0: "Participation",
      directionType1: "Atteindre (un prix dans une vente aux enchères)",
      lockStateType0: "En cours d'exécution",
      lockStateType1: "Fermé",
      lockStateType2: "Annulé",
   },
   common: {
      cardMain: "Front Side",
      cardBack: "Back",
      amount: "Amount",
      voucher: "Recharge Voucher",
      withdraw1: "Retrait du compte",
      withdraw2: "Choisissez le type",
      remark: "Remarques",
      verify:"Accréditation",
      verifySuccess:"Accréditation réussie",
      onlineService: "Service client",
      tip1: "Veuillez saisir le montant exact",
      tip2: "Solde du compte insuffisant",
      confirm: "Définir",
      cancel: "Annulations",
      tip: "Attirer l'attention sur qqch.",
      viewMore: "Voir plus",
      trade: "Transactions",
      empty: "Pas de contenu pour le moment",
      searchPairs: "Recherche de paires de devises",
      and: "Répondre en chantant",
      next: "L'étape suivante",
      success: "Succès",
      more: "Plus",
      main: "Image principale",
      sub: "Image",
      copySuccess: "Succès de copie！",
      copyError: "Échec de la reproduction！",
      view: "Vérifier",
      all: "Complet",
      goSet: "Et de mettre en place",
      available: "L'utilisabilité",
      confirmCancel: "Êtes-vous sûr de vouloir annuler ?？",
      copy: "Faire une copie de",
      submit: "Soumettre (un rapport, etc.)",
      send: "Envoi",
      keep: "Continuer à passer une commande",
      revenue: "Bénéfices attendus",
   },
   mine: {
      lastTime: "Dernière connexion",
   },
   head: {
      mineInvite: "Inviter des amis",
      mineAddress: "Adresse de retrait",
      mine: "Centre de comptes",
      orderCoin: "Ordre ponctuel",
      orderContract: "Commande de contrat",
      orderSecond: "Commandes d'options",
      order: "Ordre (d'achat)",
      assetsWithdraw: "Retirer de l'argent",
      assetsRecharge: "Chargement des pièces",
      assetsTotal: "Total des dépréciations d'actifs",
      assets: "Passif",
      logout: "Paraître (dans un journal, etc.)",
      menuTit1: "La situation actuelle du marché",
      menuTit2: "Transactions",
      menuTit3: "Gestion financière",
      menuTit4: "Nouvelles pièces en ligne",
      menuTit5: "Contest",
      menuTit6: "Event",
      menuTit7: "Blog (mot d'emprunt)",
      menuTit8: "Discover",
      menuItem0: "Négociation d'options",
      menuItem1: "Commerce de pièces de monnaie",
      menuItem2: "Négociation de contrats",
      menuItem3: "Launchpad",
      menuItem4: "Vote sur la pièce de monnaie",
      menuItem5: "Livestream",
      menuItem6: "Offline Event",
      login: "S'inscrire",
      register: "Inscription",
      download: "Scanner le code pour télécharger",
      noPermissions: "Votre adresse IP indique les pays où les services de CoinTX sont restreints.",
   },
   home: {
      h1: "The First Choice For The Initial Launch",
      p1: "Trade over 50 cryptocurrencies on CoinTX",
      tradeTab1: "Commerce de pièces de monnaie",
      tradeTab2: "Négociation de contrats",
      tradeTag1: "Hausse et baisse d'un titre en une journée de négociation",
      tradeTag2: "Monnaie chaude",
      tradeTag3: "Prime",
      serviceTit: "Une large gamme de services au choix",
      serviceTit1: "Transaction au comptant",
      servicedesc1: "Acheter, vendre ou échanger des crypto-monnaies au prix du marché.",
      serviceTit2: "Contrat perpétuel",
      servicedesc2: "Négocier des contrats en se plaçant à l'achat ou à la vente",
      serviceTit3: "Gestion financière",
      servicedesc3: "Un engagement en un clic pour augmenter les revenus quotidiens",
      serviceTit4: "Prime",
      servicedesc4: "S'abonner aux nouveaux jetons de qualité",
      liveTit: "CoinTX Live Streaming",
      whyTit: "CoinTX Live Streaming",
      whyTit1: "Sûre et digne de confiance",
      whySubtit1: "Compensation interne en temps réel, audit et gestion des risques Les portefeuilles froids sont séparés des portefeuilles multi-signatures pour protéger les fonds Audit professionnel d'une organisation de sécurité tierce, Audit professionnel d'une organisation de sécurité tierce",
      whyLink: "Voir tous les mécanismes de sécurité",
      whyTit2: "Puissant moteur d'agrégation des transactions",
      whySubtit2: "Moteur d'agrégation à très faible latence, débit massif, pas de gigue lors des pics extrêmes, pas de temps d'arrêt lors des pics.",
      whyTit3: "Plates-formes de confiance",
      whySubtit3: "CoinTX, qui figure parmi les 50 premières plateformes d'échange au monde, respecte strictement les règles du secteur et ne participe pas aux opérations de marché.",
      whyTit4: "Système de négociation rapide",
      whySubtit4: "CoinTX vous offre une interface d'échange simple et claire et un processus d'opération pratique, vous permettant d'échanger des actifs numériques plus facilement.",
      guideTit: "Guide du débutant",
      guideh1: "Créer un compte CoinTX",
      guidep1: "Inscrivez-vous maintenant et commencez votre voyage commercial !",
      guideBtn1: "Inscription",
      guideh2: "épargner de l'argent dans une banque",
      guidep2: "Recharge rapide et crédit facile !",
      guideBtn2: "Chargement des pièces",
      guideh3: "Début de l'activité",
      guidep3: "Commencez à négocier Commencez à négocier et profitez des possibilités infinies !",
      guideBtn3: "Commerce de pièces de monnaie",
      readyTop1: "Prêt à commencer ?？",
      readyMid: "CoinTX dispose d'une technologie avancée pour vous offrir une expérience d'achat, de vente et d'échange rapide. Négociez en toute sécurité grâce à notre plateforme simple, conçue spécialement pour vous.",
      readyTit: "Scan to download APP",
      readySubtit: "Android and iOS supported",
      readyDownload: "Download on the",
      readyAppStore: "AppStore",
      readyGetin: "GET IT ON",
      readyGoole: "Google Play",
      faqTit: "FAQ",
      faqTit1: "Expérience en matière de financement",
      faqCont1: "Le Crédit Futures est un bonus de prêt d'argent numérique pour les utilisateurs de CoinTX afin de les aider à comprendre le commerce de contrats perpétuels de CoinTX. Comparé au commerce traditionnel des contrats à terme de CoinTX, combiné au montant principal des crypto-actifs fournis par l'utilisateur, le Crédit Futures peut être utilisé comme une marge d'ouverture relativement importante pour le commerce du Crédit Futures.",
      faqTit2: "Qu'est-ce que CoinBox ?",
      faqCont2: "Futures CoinBox est une nouvelle fonctionnalité de CoinTX qui vous permet d'envoyer une CoinBox en crypto-monnaie à un ami.Vous pouvez créer une CoinBox en crypto-monnaie et la partager avec vos amis. Lorsque votre ami entre son mot de passe CoinBox via l'application CoinTX pour recevoir le cadeau, il recevra un montant aléatoire de crypto-monnaie à partir de la CoinBox.Le crédit peut être utilisé comme une marge relativement importante pour l'ouverture de positions dans le trading de crédit de Futures.",
      faqTit3: "Guide du débutant pour gagner de l'argent",
      faqCont3: "Futures Le dernier produit de gestion de patrimoine en crypto-monnaies de CoinTX est conçu par l'équipe quantitative de CoinTX, qui se compose de gestionnaires de fonds d'investissement quantitatifs expérimentés, d'élites financières du secteur des contrats à terme et des valeurs mobilières et de technologues des MTD. L'équipe a été établie à Singapour en 2021 et se concentre sur la gestion quantitative des actifs numériques avec des solutions professionnelles de modélisation quantitative et des capacités de gestion.Le crédit peut être utilisé comme une marge d'ouverture relativement importante dans les opérations de crédit sur Futures.",
      faqTit4: "Rejoignez-nous en direct sur CoinTX",
      faqCont4: "Futures Live est un produit de streaming en direct mettant en vedette les meilleurs projets de blockchain de mémoire et les experts de l'industrie qui livrent les dernières et les plus chaudes conférences et présentations. Rejoignez-nous pour en découvrir davantage.Le crédit peut être utilisé comme une marge d'ouverture relativement importante dans les opérations de crédit sur Futures.",
      faqTit5: "Comment acheter des crypto-monnaies sur CoinTX",
      faqCont5: "Futures CoinTX propose plusieurs fournisseurs tiers de services d'achat de crypto-monnaies aux utilisateurs du monde entier. Le crédit peut être utilisé comme marge d'ouverture relativement importante pour les opérations de crédit sur Futures.",
      partnerTit: "Partenaire coopératif",
   },
   assets: {
      tit: "Total des actifs",
      recharge: "Chargement des pièces",
      withdraw: "Retirer de l'argent",
      transfer: "Transfert",
      tab1: "Total des actifs",
      tab2: "Compte de pièces",
      tab3: "Compte du contrat",
      tab4: "Compte quantitatif contractuel (CQA)",
      tab5: "Compte financier",
      assetsTotal: "Total (général)",
      assetsReal: "L'utilisabilité",
      assetsFrozen: "Le gel (prêt, salaire, prix, etc.)",
      proportion: "Pourcentage des actifs",
      tip1: "Montant minimum de retrait",
      tip2: "Les retraits inférieurs au montant minimum ne seront pas crédités et ne pourront pas être remboursés.",
      tip3: "Montant minimum de retrait",
      linkType: "Type de chaîne",
      rechargeAddress: "Adresse de recharge",
      contractAddress: "Adresse du contrat",
      rechargeRecord: "Registre de remplissage des pièces",
      tip4: "Vous n'avez pas terminé le réglage du mot de passe, ne prenez pas en charge la recharge de pièces pour le moment.",
      withrawAddress: "Adresse de retrait",
      placeWithrawAddress: "Veuillez saisir l'adresse de retrait",
      withrawAmount: "Retrait d'argent",
      placeWithrawAmount: "Veuillez saisir le montant du retrait",
      availableLimit: "Crédit disponible",
      fee: "Frais de dossier",
      expectedReceipt: "Arrivée prévue",
      withdrawRecord: "Registres des retraits de pièces",
   },
   login: {
      login: "S'inscrire",
      register: "Inscription",
      email: "Boîte de réception",
      phone: "Téléphones mobiles",
      forget: "Mot de passe oublié ?？",
      noAccount: "Pas de compte？",
      haveAccount: "Vous avez déjà un compte？",
      agree1: "Je comprends.",
      agree2: "Accord d'utilisation",
      agree3: "Déclaration de confidentialité",
      resetPassword: "Réinitialiser le mot de passe",
      securityTip: "Afin de garantir la sécurité de vos avoirs, vous ne pouvez pas retirer de pièces 24 heures après le changement de mot de passe.",
   },
   form: {
      send: "CAPTCHA",
      labelCaptcha: "CAPTCHA, un type de test défi-réponse (informatique)",
      errorCaptcha: "Veuillez saisir le code de vérification",
      labelEmail: "Boîte de réception",
      errorEmail: "Veuillez saisir l'adresse électronique correcte",
      placeEmail: "Veuillez saisir votre adresse électronique",
      labelPhone: "Téléphones mobiles",
      errorPhone: "Veuillez saisir le numéro de téléphone mobile correct",
      placePhone: "Veuillez saisir votre numéro de téléphone portable",
      labelPassword: "Cryptographique",
      errorPassword: "Veuillez saisir le mot de passe correct",
      placePassword: "Veuillez saisir votre mot de passe",
      labelInvite: " Code d'invitation (facultatif)",
      errorInvite: "Veuillez saisir le code d'invitation",
      placeInvite: "Veuillez saisir le code d'invitation",
      placeSelece: "Veuillez sélectionner",
      account_number: "Numéro de compte",
      label_type: "Sélectionner le type",
      place_type: "Veuillez sélectionner le type",
      label_number: "Numéro de compte",
      place_number: "Numéro de compte",
      wallet_address: 'Adresse du portefeuille',
      bank_name: 'Nom de la banque',
      bank_address: "Adresse de la banque",
      name: "Nom et prénom",
      placeName: "Veuillez saisir un nom",
      payee_address: 'Adresse du bénéficiaire',
      swift_code: "Swift/Swift Code/BIC",
      aba: "ABA",
      branch_number: "Nom de la branche",
      branch_code: "Code de ligne",
      bank_code: "Code bancaire",
      phone: "Numéro de téléphone mobile",
      iban: "IBAN",
      sort_code: "Sort Code",
      placeInner: "Veuillez saisir le contenu",
      labelCurrency: "Type de monnaie",
      placeCurrency: "Veuillez sélectionner la devise",
      labelNumber: "Quantités",
      placeNumber: "Veuillez saisir la quantité",
      messageNumber: "Veuillez saisir la quantité",
      labelPrice: "Prix",
      placePrice: "Veuillez indiquer un prix",
      placeAmount: "Veuillez saisir le montant",
      labelJoinMoney: "Montant de l'adhésion",
      labelOldPassword: "Ancien mot de passe",
      placeOldPassword: "Veuillez saisir l'ancien mot de passe",
      messageOldPassword: "Veuillez saisir l'ancien mot de passe",
      psdTip: "Le mot de passe doit être une combinaison de lettres + chiffres de 6 à 16 bits.",
      labelNewPassword: "Nouveau mot de passe",
      placeNewPassword: "Veuillez saisir un nouveau mot de passe",
      messageNewPassword: "Veuillez saisir un nouveau mot de passe",
      labelCrmPassword: "Confirmer le mot de passe",
      placeCrmPassword: "Veuillez saisir à nouveau votre mot de passe",
      messageCrmPassword: "Veuillez saisir à nouveau votre mot de passe",
      labelCountry: "Pays/zone",
      placeCountry: "Veuillez sélectionner un pays",
      messageCountry: "Veuillez sélectionner un pays",
      labelId: "numéro d'identification",
      messageId: "Veuillez saisir le numéro d'identification",
      labelCaptchaEmail: "Le code de vérification sera envoyé à",
      labelTotalAmount: "Total (montant ou valeur)",
   },
   table: {
      labelName: "Nom (d'une chose)",
      labelLastPrice: "Dernier prix",
      labelRang: "24H Montée et descente",
      labelVolume: "Chiffre d'affaires 24H",
      labelVolumeNum: "Volume 24H",
      labelKline: "Diagramme en K",
      labelHeight: "24H Haut",
      labelLow: "24H Bas",
      labelOperate: "Gréement",
      labelTime: "Fois",
      labelCurrency: "Type de monnaie",
      labelAmount: "Somme d'argent",
      labelState: "état des lieux",
      state0: "Fait",
      state1: "échouer (par exemple, expériences)",
      state2: "Annulé",
      withdrawState0: "En attente d'approbation",
      withdrawState1: "Fait",
      withdrawState2: "Rejeté",
      labelDirection: "Orientations",
      labelPrice: "Prix",
      labelLever: "Barre de levier",
      labelHangNum: "Nombre ordinal",
      gmtCreate: "Temps de création",
      operate: "Gréement",
      labelPeriod: "Cycle de règlement",
      every: "Chacun",
      hour: "Heures",
      day: "Jour",
      labelFundRate: "Taux de financement",
      labelDailyRate: "Taux de financement le jour même",
      labelLevesCurrency: "Monnaie d'investissement",
      labelReturn: "Taux de rendement annualisé",
      labelLevesTerm: "Période d'investissement",
      labelStartAmount: "Montant de départ",
      viewDetails: "Voir les détails",
      labelPairs: "Contrepartie de la transaction",
      labelType: "Typologie",
      labelEntrustNumber: "Nombre de commissions",
      labelTransNumber: "Nombre de transactions",
      labelTotalAmount: "Montant total de la transaction",
      labelEntrustTime: "Temps de mise en service",
      labelBuyPrice: "Prix de l'offre",
      labelBuyNumber: "Quantité d'achat",
      labelBuyTime: "Date d'achat",
      labelSellPrice: "Taux de vente",
      labelProfitLoss: "Cire et décroissance",
      labelNumber: "Quantités",
      labelMinerTitle: "Nom financier",
   },
   foot: {
      menuTit1: "À propos de nous",
      menuTit2: "Nous contacter",
      menuTit3: "Ressource (comme la main-d'œuvre ou le tourisme)",
      menuTit4: "Main",
      menuTit5: "Transactions",
      menuItem1: "L'équipe de CoinTX",
      menuItem2: "Canal d'authentification",
      menuItem3: "Autorisation d'encaissement des entreprises",
      menuItem4: "Associer",
      menuItem5: "A propos de CoinTX",
      menuItem6: "Accord d'utilisation",
      menuItem7: "Courriel officiel",
      menuItem8: "Contrats CoinTX",
      menuItem9: "Application des pièces de monnaie",
      menuItem10: "La situation actuelle du marché",
      menuItem11: "Académie CoinTX",
      menuItem12: "Tarifs standard",
      menuItem13: "Frais de retrait et limite de retrait",
      menuItem14: "Description de la monnaie",
      menuItem15: "Caution",
      menuItem16: "Statut de remplissage et de retrait",
      menuItem17: "Suggestions et commentaires",
      menuItem18: "Centre d'aide",
      menuItem19: "Soumission des ordres de travail",
      menuItem20: "Documentation de l'API",
   },
   market: {
      spots: {
         filterTab1: "Libre-service",
         filterTab2: "Citations sur les pièces de monnaie",
         filterTab3: "Marché des contrats",
         filterTab4: "Launchpad",
         tabAll: "Complet",
         tab1: "Domaine principal",
         tab2: "Ouvrir de nouvelles voies",
         tab3: "Zone ouverte",
         tab4: "Prime",
         tab5: "Pièces de monnaie",
         tab6: "Contractuel",
         tab7: "USDT pour toujours",
         tab8: "Monnaie pleine Perpétuelle",
      }
   },
   trade:{
      chartMsg: "Informations sur les devises",
      marketPrice:"Valeur du marché",
      limitPrice:"Limite de prix",
      marketPriceTip: "Les ordres de marché sont achetés et vendus au meilleur prix du marché à ce moment-là.",
      lever: "Barre de levier",
      handNum: "Nombre ordinal",
      margin: "Marge (dans les opérations sur produits dérivés)",
      assets:"Passif",
      balance:"Soldes",
      book: "Livre de commission",
      quantity: "Montant cumulé",
      navState0: "En attente d'ouverture d'un poste",
      navState1: "Position actuelle",
      navState2: "Commissions historiques",
      navState3: "Commissions actuelles",

      fund: {
         tit1: "Marché des contrats",
         tit2: "Historique des taux de financement",
         p1: "Un fonds d'assurance pour éviter que les positions des investisseurs ne soient automatiquement réduites. Le fonds est utilisé pour améliorer le prix des mandats fermés non exécutés afin d'éviter qu'ils ne soient repris par le système de réduction automatique des positions.",
         p2: "L'augmentation du montant du fonds d'assurance provient de mandats forfaitaires solides qui sont négociés sur le marché à un prix supérieur à celui de la faillite.",
         p3: "Les soldes actuels et historiques des fonds d'assurance peuvent être consultés ci-dessous。",
         tab1: "USDT pour toujours",
         tab2: "Monnaie pleine Perpétuelle",
         contract: "Contractuel",
      },
   },
   earn: {
      tit: "Engagement en un clic, augmentation des revenus jour après jour",
      tab1: "Financement à durée déterminée",
      orderTit: "Ordres financiers",
      labelTotalApr: "Taux de rendement annualisé",
      labelTimeLimit: "Cycle du projet",
      labelMoney: "Montant de l'adhésion",
      labelType: "Méthode d'amortissement",
      labelType1: "Service de la dette à l'échéance",
      labelNowBalance: "Solde actuel",
   },
   invite: {
      tit: "Inviter des amis",
      p: "Scannez ce code QR pour inviter des amis",
      iviteLink: "Lien d'invitation",
      registerCode: "Code d'enregistrement",
   },
   noPermissions: {
      tip: "Désolé, votre zone est temporairement Jiao loi de défense semaine cette station de plate-forme, la loi noire de fournir des services à déplacer !",
   },
   order: {
      tit: "Enregistrement de la commande",
      tab1: "Commandes d'options",
      tab2: "Commandes de pièces",
      tab3: "Commande de contrat",
      tab4: "Ordres financiers",
      filterType0: "Tous les types",
      filterType1: "Fait",
      filterType2: "Annulé",
      filterType3: "Position actuelle",
      filterDirection0: "Gamme complète de directions",
      filterDirection1: "Participation",
      filterDirection2: "Atteindre (un prix dans une vente aux enchères)",
   },
   personal: {
      tit1: "Niveau de sécurité",
      tit2: "Mot de passe de connexion",
      tit3: "Mot de passe de négociation",
      tit4: "Paramètres de sécurité",
      strong: "Vigoureux",
      setting: "Mettre en place",
      settings: "Mettre en place",
      change: "Variation",
      idTip1: "Authentifier",
      idTip2: "Authentification avant de procéder au reste de l'opération",
      withdrawTip1: "Adresse de retrait",
      withdrawTip2: "Veuillez lier l'adresse de retrait",
      loginPsdTit: "Définition du mot de passe de connexion",
      withdrawPsdTit: "Définition d'un mot de passe pour les retraits",
      idTit: "Authentification par nom réel",
      phoneTit: "Collage de téléphones portables",
      phoneTip: "Pas d'annulation dans les 24 heures suivant le changement de téléphone",
      walletTit: "Portefeuille de reliure",
      accountNumber: "Numéro de compte",
      addAddress: "Ajouter une adresse",

   },
   contest: {
      hot: "Événements marquants",
   },
   city: {
		albania: "Albanie",
		algeria: "Algérie",
		argentina: "Argentine",
		armenia: "Arménie",
		australia: "Australie",
		pakistan: "Pakistan",
		austria: "Autriche",
		bahrain: "Bahreïn",
		belgium: "Belgique",
		bosnia_and_Herzegovina: "Bosnie _ Herzégovine",
		brazil: "Brésil",
		brunei: "Brunei",
		bulgaria: "Bulgarie",
		cambodia: "Cambodge",
		canada: "Au Canada",
		cameroon: "Cameroun",
		chile: "Chili",
		colombia: "Colombie",
		costa_Rica: "Costa Rica",
		croatia: "Croatie",
		cyprus: "Chypre",
		czech_Republic: "République tchèque",
		denmark: "Danemark",
		dominican_Republic: "République dominicaine",
		egypt: "Égypte",
		estonia: "Estonie",
		ethiopia: "Éthiopie",
		finland: "Finlande",
		france: "France",
		georgia: "Géorgie",
		germany: "Allemagne",
		ghana: "Ghana",
		greece: "Grèce",
		guyana: "Guyana",
		honduras: "Honduras",
		hong_Kong_China: "Hong Kong, Chine",
		hungary: "Hongrie",
		iceland: "Islande",
		ireland: "Irlande",
		italy: "Italie",
		india: "Inde",
		indonesia: "Indonésie",
		israel: "Israël",
		iran: "Iran",
		iraq: "Irak",
		japan: "Japon",
		kazakstan: "Kazakhstan",
		kenya: "Kenya",
		korea: "Corée du Sud",
		kuwait: "Koweït",
		kyrgyzstan: "Kirghizistan",
		laos: "Laos",
		latvia: "Lettonie",
		lithuania: "Lituanie",
		luxembourg: "Luxembourg",
		macao_China: "Macao, Chine",
		macedonia: "Macédoine",
		malaysia: "Malaisie",
		malta: "Malte",
		mexico: "Mexique",
		moldova: "Moldavie",
		monaco: "Monaco",
		mongolia: "Mongolie",
		montenegro: "République du Monténégro",
		morocco: "Maroc",
		myanmar: "Myanmar",
		netherlands: "Pays _ Ba",
		new_Zealand: "Nouvelle _ Zélande",
		nepal: "Népal",
		nigeria: "Nigéria",
		norway: "Norvège",
		oman: "Oman",
		palestine: "Palestine",
		panama: "Panama",
		paraguay: "Paraguay",
		peru: "Pérou",
		philippines: "Philippines",
		poland: "Pologne",
		portugal: "Portugal",
		puerto_Rico: "Porto Rico",
		qatar: "Qatar",
		romania: "Roumanie",
		russia: "Russie",
		republic_of_Trinidad_and_Tobago: "République de Trinité _ et _ Tobago",
		rwanda: "Rwanda",
		saudi_Arabia: "Arabie saoudite",
		serbia: "Serbie",
		singapore: "Singapour",
		slovakia: "Slovaquie",
		slovenia: "Slovénie",
		south_Africa: "Afrique du Sud",
		spain: "Espagne",
		sri_Lanka: "Sri Lanka",
		sweden: "Suède",
		switzerland: "Suisse",
		taiwan_China: "Taïwan, Chine",
		tajikistan: "Tadjikistan",
		tanzania: "Tanzanie",
		thailand: "Thaïlande",
		turkey: "Turquie",
		turkmenistan: "Turkménistan",
		ukraine: "Ukraine",
		united_Arab_Emirates: "Émirats arabes unis",
		united_Kingdom: "Royaume _ Uni",
		united_States: "États _ Unis",
		uzbekistan: "Ouzbékistan",
		venezuela: "Venezuela",
		vietnam: "Vietnam",
		afghanistan: "Afghanistan",
		angola: "Angola",
		azerbaijan: "Azerbaïdjan",
		bangladesh: "Bangladesh",
		belarus: "Biélorussie",
		belize: "Belize",
		benin: "Bénin",
		bhutan: "Bhoutan",
		bolivia: "Bolivie",
		botswana: "Botswana",
		british_Virgin_Islands: "Îles Vierges britanniques",
		burkina_Faso: "Burkina Faso",
		burundi: "Burundi",
		cape_Verde: "Cap _ Vert",
		cayman_Islands: "Îles Caïmans",
		central_African_Republic: "République centrafricaine",
		chad: "Tchad",
		comoros: "Comores",
		the_Republic_of_the_Congo: "Congo (Bu)",
		democratic_Republic_of_the_Congo: "Congo (or)",
		djibouti: "Djibouti",
		ecuador: "Équateur",
		el_Salvador: "Salvador",
		equatorial_Guinea: "Guinée équatoriale",
		eritrea: "Érythrée",
		fiji: "Fidji",
		gabon: "Gabon",
		gambia: "Gambie",
		greenland: "Groenland",
		guatemala: "Guatemala",
		guinea: "Guinée",
		haiti: "Haïti",
		isle_of_Man: "Île de Man",
		cote_d_Ivoire: "Côte d'Ivoire",
		jamaica: "Jamaïque",
		jordan: "Jordanie",
		lebanon: "Liban",
		lesotho: "Lesotho",
		liberia: "Libéria",
		libya: "Libye",
		madagascar: "Madagascar",
		malawi: "Malawi",
		maldives: "Maldives",
		mali: "Mali",
		mauritania: "Mauritania",
		mauritius: "Maurice",
		mozambique: "Mozambique",
		namibia: "Namibie",
		nicaragua: "Nicaragua",
		republic_of_Niger: "Niger",
		north_Korea: "Corée du Nord",
		reunion: "La réunion",
		san_Marino: "Saint _ Marin",
		senegal: "Sénégal",
		sierra_Leone: "Sierra Leone",
		somalia: "Somalie",
		sudan: "Soudan",
		suriname: "Suriname",
		eswatini: "Swaziland",
		syria: "Syrie",
		togo: "Togo",
		tonga: "Tonga",
		tunisia: "Tunisie",
		united_States_Virgin_Islands: "Îles Vierges américaines",
		uganda: "Ouganda",
		uruguay: "Uruguay",
		vatican: "Le Vatican",
		yemen: "Yémen",
		yugoslavia: "Yougoslavie",
		zambia: "Zambie",
		zimbabwe: "Zimbabwe",
		china: "Chine",
	}
};






